<!--
 * @Description: 路内停车管理系统 员工管理 巡检组管理 chargeGroupManagement
 * @Author: GeChang
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <el-row>
      <el-row class="topSelectTwoLine">
        <el-form :inline="true"
                 :model="searchForm">
          <el-row class="topFirstLine">
            <el-col :span="6">
              <el-form-item label-width="90px"
                            label="巡检组名称">
                <el-input v-model="searchForm.inspectionGroupName"
                          clearable
                          placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label-width="110px"
                            label="所属街道/城镇">
                <el-select v-model="searchForm.regionCode"
                           placeholder="请选择"
                           clearable>
                  <el-option v-for="item in regionCodeList"
                             :key="item.code"
                             :label="item.name"
                             :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="topButton">
            <el-button type="primary"
                       @click="onSubmit">查 询</el-button>
            <el-button type="info"
                       @click="resetForm"
                       style="margin-left:17px;">重 置</el-button>
          </el-row>
        </el-form>
      </el-row>
      <el-row class="tableRow">
        <el-row class="tableTitle">
          <span>巡检组管理</span>
          <!--        <el-button class="tableTitleButton"-->
          <!--                   type="success">导出</el-button>-->
          <el-button class="tableTitleButton"
                     type="success"
                     @click="addHandle">新 增</el-button>
        </el-row>
        <el-row class="tableContent">
          <dt-table :tableColumnList="tableColumnList"
                    :data="tableList"
                    :map="listMap"
                    ref="tableRef"
                    :tableConfig='tableConfig'
                    :paginationConfig='paginationConfig'
                    :config='tableConfig'
                    @getList="getList">
            <template slot="operating">
              <el-table-column label="组内巡检员">
                <template slot-scope="scope">
                  {{scope.row.inspections.length}}
                </template>
              </el-table-column>
            </template>
            <template slot="operating">
              <el-table-column label="组内巡检员">
                <template slot-scope="scope">
                  <el-button type="text"
                             size="medium"
                             @click="memberManagement(scope.row)">成员管理</el-button>
                </template>
              </el-table-column>
            </template>
            <template slot="operating">
              <el-table-column label="操作"
                               class-name='class-name'>
                <template slot-scope="scope">
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium"
                               @click="editHandle(scope.row)">修改</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium"
                               @click="deleteHandle(scope.row)">删除</el-button>
                  </el-col>
                </template>
              </el-table-column>
            </template>
          </dt-table>
        </el-row>
      </el-row>
      <!-- 巡检组新增/修改弹出框 -->
      <el-dialog :title="dialogTitle"
                 :visible.sync="dialogVisible"
                 append-to-body>
        <el-form label-width="150px"
                 :model="addForm"
                 ref="addForm"
                 :rules="rules"
                 :inline="true"
                 size="small">
          <el-row justify="space-around">
            <el-col :span="12">
              <el-form-item label="巡检组编号"
                            prop="inspectionGroupId">
                <el-input v-model="addForm.inspectionGroupId"
                          placeholder="巡检组编号"
                          :readonly="dialogType===1"
                          class="dt-form-width"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="巡检组名称"
                            prop="inspectionGroupName">
                <el-input v-model="addForm.inspectionGroupName"
                          placeholder="巡检组名称"
                          class="dt-form-width"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row justify="space-around">
            <el-col :span="12">
              <el-form-item label="所属街道/城镇"
                            prop="regionCode">
                <el-select v-model="addForm.regionCode"
                           placeholder="所属街道/城镇"
                           class="dt-form-width">
                  <el-option v-for="item in regionCodeList"
                             :key="item.code"
                             :label="item.name"
                             :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row type="flex"
                justify="end">
          <el-col :span="12"
                  class="dt-button-box">
            <el-button type="primary"
                       @click="handleClose('addForm')">确 定</el-button>
            <el-button @click="dialogVisible = false"
                       type="info">取 消</el-button>
          </el-col>
        </el-row>
      </el-dialog>
    </el-row>
    <!-- 成员管理 -->
    <el-dialog title="成员管理"
               :visible.sync="memberDialogVisible"
               append-to-body>
      <el-row>
        <el-row class="tableRow">
          <el-row class="tableTitle">
            <el-form :inline="true"
                     :model="form"
                     label-width="80px">
              <el-col :span="12">
                <el-form-item label-width="110px"
                              label="所属街道/城镇">
                  <el-select v-model="form.regionCode"
                             placeholder="请选择成员所在街道"
                             clearable
                             @change="changeRegion"
                             style="width:250px;">
                    <el-option v-for="item in regionCodeList"
                               :key="item.code"
                               :label="item.name"
                               :value="item.code">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="巡检员">
                  <el-select v-model="userCodeList"
                             multiple
                             filterable
                             collapse-tags
                             clearable
                             placeholder="请选择要添加的成员(可多选)"
                             style="width:250px;">
                    <el-option v-for="item in memberList"
                               :key="item.code"
                               :label="item.name"
                               :value="item.code"
                               :disabled="item.disabled">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form>

          </el-row>
          <el-row class="tableContent">
            <dt-table :tableColumnList="memberTableColumnList"
                      :data="memberTableList"
                      :map="memberListMap"
                      :paginationConfig='memberPaginationConfig'
                      :tableConfig="tableConfig"
                      :config='tableConfig'
                      @getList="getList">
              <template slot="operating">
                <el-table-column label="操作"
                                 class-name='class-name'>
                  <template slot-scope="scope">
                    <el-col :span="24">
                      <el-button type="text"
                                 size="medium"
                                 @click.native.prevent="deleteClick(scope.$index,scope.row,memberTableList)">删除</el-button>
                    </el-col>
                  </template>
                </el-table-column>
              </template>
            </dt-table>
          </el-row>
        </el-row>
      </el-row>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     @click="addMemberHandle">保 存</el-button>
          <el-button @click="memberDialogVisible = false"
                     type="info">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      tableFun: { 'selection-change': this.handleChange },
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      listMap: { pageNum: 1, pageSize: 15, },
      tableList: { list: [] },
      tableColumnList: [
        {
          prop: 'inspectionGroupId',
          label: '巡检组编号',
        },
        {
          prop: 'inspectionGroupName',
          label: '巡检组名称',
        },
        {
          prop: 'regionName',
          label: '所属街道/城镇',
        },
      ],
      searchForm: {},
      pageNum: 1,
      pageSize: 15, // 分页

      memberListMap: { pageNum: 1, pageSize: 10, },
      memberPaginationConfig: { layout: 'prev,pager,next,jumper', total: 0 },
      memberTableList: { list: [] },
      memberTableColumnList: [
        {
          prop: 'userCode',
          label: '编号',
        },
        {
          prop: 'userName',
          label: '名称',
        },
      ],
      massDeleteList: [], // 成员管理

      regionCodeList: [],//城镇街道下拉框

      type: 0,//显示巡检组和成员管理的type值
      dialogTitle: '',//弹出框标题
      dialogVisible: false,//弹出框显示
      dialogType: '',//弹出框类型

      //新增表单
      addForm: {
        inspectionGroupId: '',//巡检组编号
        inspectionGroupName: '',//巡检组名称
        regionCode: '',//所属街道/城镇
      },

      //表单验证
      rules: {
        inspectionGroupId: [{ required: true, message: '请输入巡检组编号', trigger: 'blur' }],
        inspectionGroupName: [{ required: true, message: '请输入巡检组名称', trigger: 'blur' }],
        regionCode: [{ required: true, message: '请选择所属街道/城镇', trigger: 'change' }],
      },

      memberDialogVisible: false, // 子表弹窗显示
      memberGroupItem: {},//每一行的数据对象
      memberList: [],//添加成员下拉列表
      form: {},
      userCodeList: []//下拉框多选的数组
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryTableList()
    this.getQueryStreet()
  },
  //方法集合
  methods: {
    // 初始
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$chargeGroupManagement.queryInspectionGroupList(this.searchForm).then((response) => {
        this.tableList.list = response.resultEntity.list
        this.paginationConfig.total = response.resultEntity.total
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 查询
    onSubmit () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    //重置
    resetForm () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.searchForm = {}
      this.queryTableList()
    },
    //新增按钮
    addHandle () {
      this.dialogVisible = true
      this.dialogType = 0
      this.dialogTitle = "新增巡检组"
      this.addForm = {}
    },
    //新增接口
    insert () {
      this.$chargeGroupManagement.insertInspectionGroup(this.addForm).then((response) => {
        if (response.resultCode == 2000) {
          this.$message({ message: '新增成功', type: 'success' });
          this.dialogVisible = false
          this.queryTableList()
        }
      })
    },

    //修改按钮
    editHandle (row) {
      this.dialogVisible = true
      this.dialogType = 1
      this.dialogTitle = "修改巡检组"
      this.addForm = this.$deepClone(row)
    },
    //修改接口
    update () {
      this.$chargeGroupManagement.updateInspectionGroup(this.addForm).then((response) => {
        if (response.resultCode == 2000) {
          this.$message({ message: '修改成功', type: 'success' });
          this.dialogVisible = false
          this.queryTableList()
        }
      })
    },
    //提交按钮
    handleClose (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogType == 0) {
            this.insert()
          } else if (this.dialogType == 1) {
            this.update()
          }
        } else {
          return false;
        }
      });
    },
    //删除按钮
    deleteHandle (row) {
      this.$confirm('确认删除该组?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let info = [{ inspectionGroupId: row.inspectionGroupId }]
        this.$chargeGroupManagement.deleteInspectionGroup(info).then((response) => {
          if (response.resultCode == 2000) {
            this.$message({ message: '删除成功', type: 'success' });
            this.queryTableList()
          }
        })
      })
    },
    //成员管理
    memberManagement (row) {
      this.memberGroupItem = row
      this.memberTableList.list = row.inspections
      this.memberPaginationConfig.total = row.inspections.length
      // this.form = {}
      this.userCodeList = []
      this.memberDialogVisible = true
    },
    // 二级联动改变街道事件
    changeRegion (val) {
      this.userCodeList = []
      this.memberList = []
      let info = {
        "columnName": [
          "user_code",
          "user_name"
        ],
        "tableName": "tb_account_info",
        "whereStr": [
          {
            "colName": "depart_id",
            "value": val //这里填你选的街道的编码
          },
          {
            "colName": "profession",
            "value": "XJY"
          },
          {
            "colName": "user_status",
            "value": "0"
          }
        ]
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.memberList = response.resultEntity
        for (let i = 0; i < this.memberTableList.list.length; i++) {
          for (let j = 0; j < this.memberList.length; j++) {
            if (this.memberTableList.list[i].userCode == this.memberList[j].code) {
              this.memberList[j].disabled = true
            }
          }
        }
      })
    },
    //成員管理----->添加成员
    addMemberHandle () {
      this.$confirm('确认添加选中的成员?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let temp = []
        this.memberGroupItem.inspections.forEach(item => {
          temp.push({ userCode: item.userCode })
        });
        this.userCodeList.forEach(item => {
          temp.push({ userCode: item })
        });
        let info = {
          inspectionGroupId: this.memberGroupItem.inspectionGroupId,
          regionCode: this.memberGroupItem.regionCode,
          inspections: temp
        }
        this.$chargeGroupManagement.editGroupMember(info).then((response) => {
          if (response.resultCode == 2000) {
            this.$message({ message: '修改成员成功', type: 'success' });
            this.memberDialogVisible = false
            this.queryTableList()
          }
        })
      })
    },
    // 成員管理----->删除成员
    deleteClick (index, row, rows) {
      this.$confirm('确认将该成员从本组删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let arr = []
        this.memberTableList.list.forEach(item => {
          arr.push({ userCode: item.userCode })
        });
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].userCode == row.userCode) {
            arr.splice(i, 1);
          }
        }
        let info = {
          inspectionGroupId: this.memberGroupItem.inspectionGroupId,
          regionCode: this.memberGroupItem.regionCode,
          inspections: arr
        }
        this.$chargeGroupManagement.editGroupMember(info).then((response) => {
          if (response.resultCode == 2000) {
            this.$message({ message: '删除成员成功', type: 'success' });
            rows.list.splice(index, 1);
            this.queryTableList()
          }
        })
      })
    },
    //获取所属街道
    getQueryStreet () {
      let info = {
        "columnName": [
          "region_code",
          "region_name"
        ],
        "tableName": "tb_region"
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.regionCodeList = response.resultEntity
      })
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
//@import url(); 引入公共css类
.mainBody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.dt-form-width {
  width: 230px;
}
</style>
